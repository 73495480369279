import request from '../Axios'

// 列表
export const ordersQueryCriteria = data => {
  return request({
    url: '/orders/queryCriteria',
    method: 'post',
    data
  })
}

// 详情
export const ordersDetail = params => {
  return request({
    url: `/orders/detail/${params.id}`,
    method: 'get'
  })
}

// 取消
export const ordersCancel = params => {
  return request({
    url: `/orders/cancel/${params.id}`,
    method: 'put'
  })
}

// 删除
export const ordersDel = params => {
  return request({
    url: `/orders/del/${params.id}`,
    method: 'delete'
  })
}

// 备注
export const ordersRemarks = params => {
  return request({
    url: `/orders/remarks/${params.id}?remarks=${params.remarks}`,
    method: 'put'
  })
}

// 发货
export const ordersSendDelivery = data => {
  return request({
    url: '/orders/sendDelivery',
    method: 'post',
    data
  })
}
