<template>
  <div class="tables">
    <el-table
      v-loading="loading"
      :data="data"
      style="width: 100%"
      :height="wrapperHeight"
      border
      :cell-style="cellStyle"
      :highlight-current-row="highlightCurrent"
      element-loading-text="Loading..."
      :element-loading-spinner="svg"
      element-loading-svg-view-box="-10, -10, 50, 50"
      @header-click="handleHeaderClick"
      @selection-change="handleTableCurrentChange"
    >
      <!-- :header-cell-style="tableHeader" -->
      <!-- @row-click="handleTableRowClick" -->
      <!--
      wrapperHeight：高度
      highlightCurrent：选择单行高亮
      handleTableCurrentChange：复选框选中
      handleTableRowClick：点击单行
     -->
      <template v-for="(item, index) in columns">
        <!-- 选择框 -->
        <el-table-column
          v-if="item.selection"
          :key="`selection_${index}`"
          type="selection"
          align="center"
          :resizable="false"
        ></el-table-column>
        <!-- 序号 -->
        <el-table-column
          v-else-if="item.index"
          :key="`index_${index}`"
          :width="item.width"
          type="index"
          :label="item.label"
          align="center"
          :resizable="false"
        ></el-table-column>
        <!-- 多级表头 -->
        <el-table-column
          v-else-if="item.multi"
          :key="`multi_${index}`"
          :resizable="false"
          align="center"
          :label="item.label"
        >
          <el-table-column
            v-for="(child, childIndex) in item.children"
            :key="`child_${index}_${childIndex}`"
            v-bind="child"
            :resizable="false"
          >
          </el-table-column>
        </el-table-column>
        <!-- 自定义内容 -->
        <slot
          v-else-if="item.slot"
          show-overflow-tooltip
          :name="item.slot"
          :resizable="false"
          align="center"
          :fixed="item.fixed"
        ></slot>
        <!--常规内容-->
        <el-table-column
          v-else
          :key="`normal_${index}`"
          align="center"
          v-bind="item"
          :resizable="false"
        >
        </el-table-column>
      </template>
    </el-table>
    <div class="block" v-if="isPaginationShow && pagination.total">
      <el-pagination
        :current-page="pagination.current"
        :page-size="pagination.size"
        :page-sizes="pageSizesNum"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
const props = defineProps({
  columns: {
    type: Array,
    default: () => []
  },
  data: {
    type: Array,
    default: () => []
  },
  pagination: {
    type: Object,
    default: () => ({
      current: 1,
      size: 10,
      total: 0
    })
  },
  pageSizesNum: {
    type: Array,
    default: () => [10, 20, 30, 40]
  },
  isPaginationShow: {
    type: Boolean,
    default: true
  },
  wrapperHeight: {
    type: [Number, String],
    default: 'auto' // 一般情况下，更多地是配置父元素的高度，table的高度根据父元素高度计算
  },
  height: {
    type: [Number, String],
    default: 'calc(100% - 48px)' // table的默认高度是 父元素高度 减去 pagination高度，一般情况下，这个默认配置就够用了，有特殊场景可以另配置
  },
  maxHeight: {
    type: [Number, String],
    default: 'auto'
  },
  tableSize: {
    type: String,
    default: 'small'
  },
  stripe: {
    type: Boolean,
    default: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  highlightCurrent: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['cellStyle', 'getData', 'headerClick'])
const svg = `
<path class="path" d="
  M 30 15
  L 28 17
  M 25.61 25.61
  A 15 15, 0, 0, 1, 15 30
  A 15 15, 0, 1, 1, 27.99 7.5
  L 15 15
" style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>`
const cellStyle = e => {
  let obj = {}
  emit('cellStyle', e, (color = {}) => {
    // 将事件返回到调用组件的页面去判断 应该现实的颜色
    obj = color
  })
  return obj
}
// 切换页码
const handleCurrentChange = val => {
  emit('getData', val, 'current')
}

// 切换页条
const handleSizeChange = val => {
  emit('getData', val, 'size')
}

// 单选
const handleTableCurrentChange = currentRow => {
  emit('changeCurrent', currentRow)
}

// 当某一列的表头被点击时会触发该事件
const handleHeaderClick = currentRow => {
  emit('headerClick', currentRow)
}

// const tableHeader = () => {
//   return {
//     backgroundColor: '#163168',
//     color: '#fff'
//   }
// }

// 点击行
// const handleTableRowClick = (currentRow) => {
//   console.log('rowClick', currentRow)
//   emit('rowClick', currentRow)
// }
</script>
<style lang="scss" scoped>
.block {
  margin-top: 20px;
  display: flex;
  justify-content: right;
}
</style>
<style lang="scss">
.tables {
  // .el-table__body-wrapper,
  // .el-scrollbar__wrap {
  //   height: 414px !important;
  // }
  .el-scrollbar__wrap {
    display: flex;
  }
  // .el-table {
  //     font-size: 16px;
  //     background: transparent;
  //     td.el-table__cell,
  //     th.el-table__cell.is-leaf {
  //       border: none;
  //     }
  //     .el-table__inner-wrapper::before {
  //       display: none;
  //     }
  //     tr {
  //       background: transparent;
  //       color: #fff;
  //     }
  //     tbody tr:hover > td {
  //       background: transparent !important;
  //     }
  //     .el-table__body tr.current-row > td.el-table__cell {
  //       background: transparent !important;
  //     }
  //   }
  //   .el-pagination .btn-next, .el-pagination .btn-prev {
  //     background: transparent;
  //     color: #fff;
  //   }
  //   .el-pager li {
  //     background: transparent;
  //   }
  //   .el-pagination__total {
  //     color: #fff;
  //   }
  //   .el-pagination__jump {
  //     color: #fff;
  //   }
  //   .el-input {
  //     background: transparent;
  //     color: #fff;
  //     .el-input__wrapper {
  //       background: transparent;
  //       color: #fff;
  //       .el-input__inner {
  //         color: #fff;
  //       }
  //     }
  //   }
}
</style>
