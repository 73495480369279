<template>
  <div class="order-detail">
    <div class="top">
      <h6>订单详情</h6>
      <div class="back" @click="cancel">返回上一级</div>
    </div>
    <el-form class="demo-form-inline">
      <el-card class="!border-none mt-4" shadow="never">
        <div
          class="pb-4 mb-4 text-lg font-medium"
          style="border-bottom: 1px solid #eeeeee"
        >
          订单信息
        </div>

        <div class="flex mt-4">
          <div class="w-2/5">
            <el-form-item label="订单编号">
              {{ orderDetail.orderSn || '-' }}
            </el-form-item>
            <el-form-item label="订单状态">
              {{ orderStatus(orderDetail.orderStatus) }}
            </el-form-item>
            <el-form-item label="订单类型">
              {{ orderType(orderDetail.orderType) }}
            </el-form-item>
            <el-form-item label="下单时间">
              {{ orderDetail.createTime || '-' }}
            </el-form-item>
            <el-form-item label="完成时间">
              {{ orderDetail.confirmTime || '-' }}
            </el-form-item>
            <el-form-item label="买家留言">
              {{ orderDetail.userRemark || '-' }}
            </el-form-item>
          </div>
          <div class="w-3/5">
            <el-form-item label="支付状态">
              <span class="text-error" v-if="orderDetail.payIs === 0">
                未支付
              </span>
              <span v-if="orderDetail.payIs === 1"> 已支付 </span>
            </el-form-item>
            <el-form-item label="订单来源">
              {{ orderDetail.orderSource == 1 ? '微信小程序' : '其他' }}
            </el-form-item>
            <el-form-item label="支付方式">
              {{ payWay(orderDetail.payWay) }}
            </el-form-item>
            <el-form-item label="买家信息">
              {{ orderDetail.addressContact || '-' }}
            </el-form-item>
            <el-form-item label="支付时间">
              {{ orderDetail.payTime || '-' }}
            </el-form-item>
            <el-form-item label="商家备注">
              <div class="break-all">
              {{ orderDetail.shopRemark || '-' }}
              </div>
            </el-form-item>
          </div>
        </div>

        <div class="pt-4" style="border-top: 1px solid #eeeeee" v-if="false">
          <el-button type="danger"> 取消订单 </el-button>
          <el-button type="primary"> 确认收货 </el-button>
          <el-button type="primary"> 去发货 </el-button>
          <el-button type="primary"> 查看物流 </el-button>
          <el-button type="default"> 商家备注 </el-button>
        </div>
      </el-card>

      <el-card class="!border-none mt-4" shadow="never">
        <div
          class="pb-4 mb-4 text-lg font-medium"
          style="border-bottom: 1px solid #eeeeee"
        >
          收货及物流信息
        </div>

        <div class="flex">
          <div class="w-2/5">
            <el-form-item label="配送方式">
              {{ deliveryType(orderDetail.deliveryType) }}
            </el-form-item>
            <el-form-item label="收货人">
              {{ orderDetail.addressContact || '-' }}
            </el-form-item>
            <el-form-item label="手机号码">
              {{ orderDetail.addressMobile || '-' }}
            </el-form-item>
            <el-form-item label="收货地址">
              {{ orderDetail.addressContent || '-' }}
            </el-form-item>
          </div>
          <div class="w-3/5">
            <el-form-item label="发货状态">
              {{ orderDetail.orderStatus == 3 ? '已发货' : '-' }}
            </el-form-item>
            <el-form-item label="物流公司">
              {{ orderDetail.expressName || '-' }}
            </el-form-item>
            <el-form-item label="物流单号">
              {{ orderDetail.expressNo || '-' }}
            </el-form-item>
            <el-form-item label="发货时间">
              {{ orderDetail.expressTime || '-' }}
            </el-form-item>
          </div>
        </div>
      </el-card>

      <el-card class="!border-none mt-4" shadow="never">
        <div
          class="pb-4 mb-4 text-lg font-medium"
          style="border-bottom: 1px solid #eeeeee"
        >
          商品信息
        </div>
        <!-- 商品表格 -->
        <TableView
          :loading="loading"
          :columns="columns"
          :data="orderDetail.orderGoodsList"
          :is-pagination-show="isPaginationShow"
        >
          <template #goodsPrice>
            <el-table-column v-if="orderDetail.orderType == 1" label="单价（元）" align="center">
              <template #default="scoped">
                  <span>{{ scoped.row.goodsPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="orderDetail.orderType == 2" label="单价（积分）" align="center">
              <template #default="scoped">
                <span>{{ scoped.row.goodsPrice }}</span>
              </template>
            </el-table-column>
          </template>
          <template #goodsName>
            <el-table-column label="商品信息" align="center">
              <template #default="scoped">
                <div class="goodsinfo">
                  <img :src="scoped.row.goodsImage" />
                  <span>{{ scoped.row.goodsName }}</span>
                </div>
              </template>
            </el-table-column>
          </template>
        </TableView>
        <!-- 商品总结 -->
        <div class="w-[200px] ml-auto goodall" v-if="orderDetail.orderType == 1">
          <div class="flex justify-between mt-4">
            <span>商品总额: </span>
            <span>¥{{ orderDetail.goodsMoney }}</span>
          </div>
          <div class="flex justify-between mt-4">
            <span>运费金额: </span>
            <span>¥{{ orderDetail.expressMoney }}</span>
          </div>
          <div class="flex justify-between mt-4">
            <span>优惠金额: </span>
            <span>-¥{{ orderDetail.couponMoney }}</span>
          </div>
          <div class="flex justify-between mt-4">
            <span>应付金额:</span>
            <span class="text-xl text-error"
              >¥{{ orderDetail.needPayMoney }}</span
            >
          </div>
        </div>
        <div class="w-[200px] ml-auto goodall" v-if="orderDetail.orderType == 2">
          <div class="flex justify-between mt-4">
            <span>商品总额: </span>
            <span>{{ orderDetail.goodsMoney }}积分</span>
          </div>
          <div class="flex justify-between mt-4">
            <span>优惠积分: </span>
            <span>-{{ orderDetail.deductionIntegral }}积分</span>
          </div>
          <div class="flex justify-between mt-4">
            <span>应付积分:</span>
            <span class="text-xl text-error"
            >{{ orderDetail.needPayMoney }}积分</span
            >
          </div>
        </div>
      </el-card>

      <el-card class="!border-none mt-4" shadow="never" v-if="false">
        <div
          class="pb-4 mb-4 text-lg font-medium"
          style="border-bottom: 1px solid #eeeeee"
        >
          订单日志
        </div>
        <!-- 操作日志表哥 -->
        <el-table size="large">
          <el-table-column prop="operatorName" label="操作人" min-width="100" />
          <el-table-column prop="content" label="操作内容" min-width="100" />
          <el-table-column prop="createTime" label="记录日期" min-width="100" />
        </el-table>
      </el-card>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs } from 'vue'
import TableView from '@/components/TableView.vue'
import { ElMessage } from 'element-plus'
import { ordersDetail } from '@/api/orderManagement/orderList'
import { useRouter } from 'vue-router'
const router = useRouter()
const initData = reactive({
  loading: false,
  isPaginationShow: false,
  rTableData: [],
  columns: [
    { prop: 'goodsId', label: '商品货号' },
    { slot: 'goodsName' },
    { slot: 'goodsPrice' },
    // { prop: 'goodsPrice', label: '单价（元）' },
    { prop: 'goodsNum', label: '购买数量（件）' },
    { prop: 'goodsMoney', label: '商品总价' },
    { prop: 'needPayMoney', label: '应付金额' },
    { slot: 'action' }
  ],
  orderDetail: {}
})

const { loading, rTableData, columns, orderDetail,isPaginationShow } = toRefs(initData)

onMounted(() => {
  if (router.currentRoute.value.query.id) {
    getData(router.currentRoute.value.query.id)
  }
})
// 返回上一级、取消
const cancel = () => {
  router.push('/orderList')
}

const getData = id => {
  ordersDetail({ id: id }).then(({ data: res }) => {
    if (res.code == 200) {
      orderDetail.value = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}


// 订单状态
const orderStatus = val => {
  let v = ''
  switch (val) {
    case 1:
      v = '待付款'
      break
    case 2:
      v = '待发货'
      break
    case 3:
      v = '已发货'
      break
    case 4:
      v = '待核销'
      break
    case 5:
      v = '已完成'
      break
    case 6:
      v = '已取消'
      break
  }
  return v
}

// 支付方式
const payWay = val => {
  let v = '-'
  switch (val) {
    case 0:
      v = '其他'
      break
    case 1:
      v = '微信支付'
      break
    case 2:
      v = '支付宝支付'
      break
  }
  return v
}

// 配送方式
const deliveryType = val => {
  let v = '-'
  switch (val) {
    case 1:
      v = '快递邮寄'
      break
    case 2:
      v = '门店自提'
      break
    case 3:
      v = '立即发放'
      break
  }
  return v
}

// 订单类型
const orderType = val => {
  let v = '-'
  switch (val) {
    case 1:
      v = '现金支付'
      break
    case 2:
      v = '积分支付'
      break
    case 3:
      v = '混合支付'
      break
  }
  return v
}
</script>
<style lang="scss" scoped>
.goodall {
  font-size: 14px;
}

.goodsinfo {
  img {
    width: 60px;
    height: 60px;
    vertical-align: middle;
    margin-right: 5px;
  }
}
</style>
